.formulario {
    display: flex;
    flex-wrap: wrap;
    max-width: 25vw;
    justify-content: center;
    gap: 2vw;
}
.formulario div {
    margin-bottom: -3vh;
    width: 100%;
}
.formulario p {
    font-weight: bold;
    margin-bottom: 2%;
}
.formulario input {
    width: 100%;
    height: 4vh;
    background-color: #000;
    border-radius: 2px;
    border: solid 1px #D3D3D3;
    padding-left: 1vw;
    color: #D3D3D3; 
}

.formulario textarea {
    width: 100%;
    height: 20vh;
    background-color: #000;
    border-radius: 2px;
    border: solid 1px #D3D3D3;
    padding-left: 1vw;
    padding-top: 1vh;
    color: #D3D3D3;
    resize: none;
}

.inputText {
    width: 100%;
    height: 4vh;
    background-color: #000;
    border-radius: 2px;
    border: solid 1px #D3D3D3;
    padding-left: 1vw;
    color: #D3D3D3; 
}

#btn {
    background-color: #D3D3D3;
    color:#000;
    height: 6vh;
    border-radius: 2px;
    width: 99%;
    font-weight: bold;
    box-sizing: content-box;
}

#btn:hover {
    cursor: pointer;
    background-color: #000;
    color: #D3D3D3;
    border-color: #D3D3D3;
}

.email-invalid {
    color: red;
    font-weight: lighter;
    font-size: 12px;
    margin-top: 2px;
    margin-left: 1vw;
}

.input-error {
    border-color: red !important;
    color: red;
}

@media screen and (max-width: 480px) {
    .formulario {
        max-width: 100%;
    }   
}