.materiais {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    margin-right: 1vw;
}

.formatos-grid {
    height: 100%;
    border: #D3D3D3 1px solid;
    width: 15vw;
    border-radius: 8px;
    margin-left: 3vw;
}
.formatos-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1vw;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}
.formatos-items:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.formatos-items:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.formatos-items:hover {
    color: #000;
    background-color: #D3D3D3;
}

.formatos-items span {
    margin-left: 1vw;
}

.formatos-items-active {
    color: #000;
    background-color: #D3D3D3;
}

.materiais-btn {
    cursor: pointer;
}

.materiais-btn:hover {
    color: #FFF;
}

.materiais-btn-active {
    color: #FFF;
    font-weight: bold;
}

.image-metais-calculadora{
    width: 20vw;
}
.image-metais-calculadora img {
    width: 100%;
}

.calc {
    display: flex;
    gap: 10vw;
    margin-top: 10vh;
}

.container-calc {
    display: flex;
    gap: 5vw;
}
#calc-form {
    width: 19vw;
}
#calc-form div p span {
    background-color: #D3D3D3;
    padding-inline: 2%;
    padding-block: 1%;
    color: #000;
    text-align: center;
    font-weight: bold;
}

#calc-form div input {
    width: 100%;
    border: solid 1px #D3D3D3;
    color: #D3D3D3;
    background-color: #000;
    height: 5vh;
    border-radius: 4px;
    margin-top: -8vh;
    padding-left: 1vw;
}

#alerta {
    margin-top: -4%;
    margin-left: 25%;
    width: 70%;
    line-height: 3vh;
}

@media screen and (max-width: 480px) {
    .materiais {
        max-width: 90%;
        flex-wrap: wrap;
        margin-left: 5%;
        margin-top: 10%;
    }
    .calc {
        flex-direction: column;
        gap: 5vh;
        margin-top: 10vh;
        align-items: center;
    }
    .formatos-grid {
        width: 80%;
    }
    .container-calc {
        flex-direction: column;
        width: 80%;
    }

    .image-metais-calculadora{
        width: 100%;
        text-align: center;
    }
    .image-metais-calculadora img {
        width: 85%;
    }

    #calc-form {
        width: 95%;
        margin-bottom: 10%;
    }

    #alerta {
        text-align: center;
        width: 100%;
        margin-left: 0%;
    }
}