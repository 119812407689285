.card-why {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    flex-wrap: wrap;
    margin-top: 15vh;
}

@media screen and (max-width: 480px) {
    .card-why {
        min-width: 90vw;
        max-width: 90vw;
    }
}