body {
    background-color: #000;
    color: #D3D3D3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 100%;
    margin-top: 20vh;
    justify-content: space-evenly;
    display: flex;
}

.content-container {
    width: 50%;
    text-align: justify;
}

.section-title-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.section-title-container h3 {
    min-width: 30%;
}

hr {
    width: 100%;
    height: 0.2px;
    border: none;
    background-color: #D3D3D3;
}

.list {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 8vw;
}

.list li {
    width: 45%;
    text-align: start;
    list-style-image: url('../../assets/icon-list.svg');   
}
.section-formatos {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 4vw;
    max-width: 80%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.formato {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-produto {
    width: 30vw;
    max-width: 30vw;
    min-width: 30vw;
    margin-top: 4vh;
}

@media screen and (max-width: 480px) {
    .container {
        flex-direction: column;
        margin-top: 5%;
    }
    .content-container {
        width: 90%;
        margin: 5%;
    }
    .image-produto {
        width: 90%;
        max-width: 90%;
        min-width: 90%;
        margin: 5%;
    }
}