html {
    scroll-behavior: smooth;
}
.body {
    overflow-x: hidden;
    color: #D3D3D3;
}
.header-container {
    background-image: url('../../assets/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.header-txt-container {
    height: 60vh;
    display: flex;
    margin-left: 50vw;
    width: 40vw;
    flex-direction: column;
    line-height: 8vh;
    align-items: center;
    justify-content: center;
    color: #D3D3D3;
}

.header-txt-container h1 {
    font-size: 3rem;
    -webkit-text-fill-color: #D3D3D3;
    -webkit-text-stroke: 2px #000;
    margin-bottom: 0vh;
} 
.header-txt-container h2 {
    line-height: 4vh;
}

.header-button {
    width: 20vw;
    height: 8vh;
    font-family: 'Butzke';
    border: 1px solid #000;
    border-radius: 8px;
    background-color: #D3D3D3;
    margin-top: -5vh;
    margin-left: 50vw;
    overflow-y: overlay;
}
 
.header-button:hover{
    background-color: #000;
    border: 1px solid #D3D3D3;
    color: #D3D3D3;
    cursor: pointer;
    transition: 0.4s;
}

.section-container {
    min-height: 100vh;
    background-color: #000;
    opacity: 0.9;
    max-width: calc(100vw - 17px); 
}

#produtos-container {
    padding: 2% 4% 0%;
}

#ttl-produtos-container {
    text-align: start;
    line-height: 2px;
    margin-left: 3vw;
}
#ttl-produtos-container h1 {
    font-size: 3rem;
}
#ttl-produtos-container h2 {
    color: #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D3D3D3;
    font-size: 3rem;    
}
#cabecalho-produtos-container {
    display: flex;
    align-items: center;
}
#cabecalho-produtos-container hr {
    width: 64vw;
    height: 1px;
    background-color: #D3D3D3;
    margin-left: 4vw;
}
.produtos-card-container {
    max-width: 100vw;
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

#why-container {
    padding: 2% 4% 0%;
}

#ttl-why-container {
    text-align: start;
    line-height: 2px;
    margin-right: 1vw;
}
#ttl-why-container h1 {
    font-size: 4rem;
}
#ttl-why-container h2 {
    color: #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D3D3D3;
    font-size: 3rem;    
}
#cabecalho-why-container {
    display: flex;
    align-items: center;
}
#cabecalho-why-container hr {
    width: 64vw;
    height: 1px;
    background-color: #D3D3D3;
    margin-left: 3vw;
}
.motivos-box {
    display: flex;
    gap: 4vw;
    margin-left: 2vw;
    margin-right: 2vw;
    text-align: center;
}

#peso-container {
    padding: 1% 4% 0%;
    padding-bottom: 2%;
}

#ttl-peso-container {
    text-align: start;
    width: 30vw;
    line-height: 2px;
    margin-left: 3vw;
}
#ttl-peso-container h1 {
    font-size: 3rem;
}
#ttl-peso-container h2 {
    color: #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D3D3D3;
    font-size: 2rem;    
}
#cabecalho-peso-container {
    display: flex;
    align-items: center;
}
#cabecalho-peso-container hr {
    width: 60vw;
    height: 1px;
    background-color: #D3D3D3;
    margin-left: 3vw;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#contato-container {
    padding-top: 8vh;
    padding-bottom: 20vh;
}

#ttl-contato-container {
    text-align: center;
    line-height: 2px;
}
#ttl-contato-container h1 {
    font-size: 3rem;
}
#ttl-contato-container h2 {
    color: #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D3D3D3;
    font-size: 2rem;    
}
#cabecalho-contato-container {
    display: flex;
    align-items: center;
}
#cabecalho-contato-container hr {
    width: 30vw;
    height: 1px;
    background-color: #D3D3D3;
    margin-left: 6vw;
    margin-right: 6vw;
}
.contato-box {
    display: flex;
    width: 100vw;
    gap: 8vw;
    padding-top: 6vh;
}
.formulario-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 480px) {
    .motivos-box {
        flex-direction: column;
        margin-bottom: 5vh;
    }
    .contato-box {
        flex-direction: column;
        align-items: center;
    }
    #cabecalho-why-container {
        flex-direction: column-reverse;
    }
    #cabecalho-why-container hr {
        width: 95%;
    }
    .header-txt-container {
        width: 95%;
        margin-left: 5%;
    }

    .header-txt-container h1 {
        -webkit-text-fill-color: #D3D3D3;
        -webkit-text-stroke: 0px #000;
        font-size: 3rem;
    } 
    .header-button {
        width: 50%;
        margin-left: 25%;
        color: #000;
    }

    #cabecalho-peso-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #ttl-peso-container {
        width: 100%;
        line-height: 2px;
        text-align: center;
    }
    #ttl-peso-container h2 {
        color: #D3D3D3;   
    }

    #cabecalho-peso-container hr {
        width: 95%;
        height: 1px;
        margin-left: 3%;
    }

    .formulario-container {
        width: 80%;
    }
}