.main {
    padding-inline: 5vw;
    padding-block: 10vh;
    display: flex;
    justify-content: space-around;
    gap: 5vw;
}
.txt-container {
    width: 50%;
}
.txt {
    text-align: justify;
}

.table-container {
    display: flex;
    flex-direction: column;
    width: 50%;
}
.table-container h3 {
    width: 100%;
}
.header-table {
    background-color: #D3D3D3;
    color: #000;
    width: 100%;
    text-align: center;
    border: #000 solid 1px;
}
.subtable {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

select {
    width: 100%;
    border: solid 1px #000;
    height: 150px;
    padding: 1%;
}
.selects-container {
    display: flex;
}

.resultado {
    display: flex;
    width: 98%;
    height: 3vh;
    background-color: #D3D3D3;
    color: #000;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1%;
    font-size: 14px;
    font-weight: bold;
}

.grid {
    width: 50%;
    margin-bottom: 2px;
}

@media screen and (max-width: 480px) {
    .main {
        flex-direction: column;
        width: 100%;
    }

    .txt-container {
        width: 90%;
    }
    .table-container {
        width: 90%;
    }
    select {
        height: 30px;
        color: #000;
        border-radius: 0px;
    }
}