.menu-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

a {
    text-decoration: none;
    color: #D3D3D3;
}

.itens-menu {
    display: inline-block;
    position: relative;
    margin-right: 5vw;
    font-weight: bold;
}

.menu-container ul li:hover{    
    cursor: pointer;
}

.menu-container ul li > a::after {
    content: "";
    display: block;
    margin-top: 3px;
    width: 0%;
    height: 1px;
    border-radius: 200px;
    background: rgba(226,226,226,1);
    background: -moz-linear-gradient(left, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(211,211,211,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(226,226,226,1)), color-stop(50%, rgba(219,219,219,1)), color-stop(51%, rgba(209,209,209,1)), color-stop(100%, rgba(211,211,211,1)));
    background: -webkit-linear-gradient(left, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(211,211,211,1) 100%);
    background: -o-linear-gradient(left, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(211,211,211,1) 100%);
    background: -ms-linear-gradient(left, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(211,211,211,1) 100%);
    background: linear-gradient(to right, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(211,211,211,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#d3d3d3', GradientType=1 );
    transition: all 0.3s ease;
}

.menu-container ul li > a:hover::after {
    width: 120%;

}

.logo {
    width: 30vw;
}

.logo-mobile {
    width: 70vw;
}
.header-menu-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.menu-hamburguer {
    height: 100%;
    width: 2em;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-right: 5vw;
    justify-content: center;
    cursor: pointer;
}

.barra-menu-hamburguer {
    width: 100%;
    height: 4px;
    background-color: #D3D3D3;
}

.menu-mobile-active{
    width: 100%;
    height: 100rem;
}

.hidden {
    display: none;
}
.lista-itens-menu-mobile {
    display: flex;
    flex-direction: column;
    gap: 4vh;
}
.itens-menu-mobile {
    display: flex;
    margin-right: 5vw;
    font-weight: bold;
    font-size: 24px;
}

.barra-menu-hamburguer.clicked:nth-child(1) {
    transform: rotate(45deg) translate(-0.55em, 1.25em);
    transition: ease-out 0.5s;
}

.barra-menu-hamburguer.clicked:nth-child(2) {
    display: none;
}

.barra-menu-hamburguer.clicked:nth-child(3) {
    transform: rotate(135deg) translate(0.85em, 1em);
    transition: ease-out 0.5s;
}

.barra-menu-hamburguer.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s;
}
