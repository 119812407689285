.card {
    min-width: 40vw;
    max-width: 40vw;
    margin-bottom: 5vh;
    margin-left: 2vw;
    display: flex;
}

.image-container {
    min-width: 20vw;
    min-height: 25vh;
    max-width: 20vw;
    max-height: 25vh;
}

.image-container img {
    width: 100%;
    height: 100%;
}

.grade{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

#nome-produto {
    margin-top: -1%;
    font-weight: bold;
    text-align: center;
}

#text-produto {
    margin-top: -2%;
    margin-left: 2vw;
    text-align: justify;
    font-size: 14px;
}

.ancora-produto {
    border: solid 1px #D3D3D3;
    padding: 4px;
    margin-top: -5%;
    font-size: 10px;
    font-weight: bold;
}

.ancora-produto:hover{
    color: #000;
    background-color: #D3D3D3;
    opacity: 0.9;
    font-weight: bold;
}

@media screen and (max-width: 480px) {
    .card {
        min-width: 80vw;
        max-width: 80vw;
    }
    .image-container {
        min-width: 35vw;
        min-height: 25vh;
        max-width: 35vw;
        max-height: 25vh;
    }
}